<template>
  <div>
    <RegionForm ref="FormModal" @add-payload="onAddRegionPayload" @edit-payload="onEditRegionPayload" :title="form_title" />
    <ConfirmModal ref="DeleteRegionConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CRow class="justify-content-center">
            <strong class="h4">{{myOrganization.name}}</strong>
          </CRow>
        </slot>
      </CCardHeader>

      <CCardBody>
        <div class="d-flex flex-wrap justify-content-left">
          <div class="col-md-12 col-lg-5">
            <CImg style="width:100%; height:300px" :src="logo" class="p-5 custom-img-contain" />
          </div>
          <div>
            <CCallout class="w-2">
              <small class="text-muted">Service Subscription</small><br>
              <CBadge
                class="mr-1"
                v-for="service in myOrganization.subscription"
                :color="$utility.getSubscriptionStatusColor(service)"
                v-c-tooltip="$utility.getSubscriptionStatusRemain(service)"
                :key="`service-subscription-${myOrganization.id}-${service.id}`"
              >{{service.service_name}}</CBadge>
              <!-- <el-tooltip
                v-for="service in myOrganization.subscription"
                :key="`service-subscription-${myOrganization.id}-${service.id}`"
                :content="getSubscriptionStatusRemain(service)"
              >
                <el-tag class="mr-1" size="small" :type="getSubscriptionStatusColor(service)">{{ service.service_name }}</el-tag>
              </el-tooltip> -->
              <br/><br/>
              <small class="text-muted">Users</small><br>
              <strong class="h5">{{myOrganization.num_user}}</strong> / {{myOrganization.max_user}}<br><br>
              <small class="text-muted">Devices</small><br>
              <strong class="h5">{{myOrganization.num_device}}</strong> / {{myOrganization.max_device}}<br><br>
              <small class="text-muted">Branches</small><br>
              <strong class="h5">{{myOrganization.num_branch}}</strong><br><br>
              <small class="text-muted">Organization ID</small><br>
              <CButton v-if="!view_sid" color="secondary" size="sm" @click="view_sid=!view_sid">Inquire</CButton>
              <span v-else>
                <strong class="h5">{{myOrganization.sid}}</strong>
                <i class="ml-2 el-alert__icon el-icon-copy-document info-icon" @click="onClipboardCopy" />
              </span>
            </CCallout>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <div class="d-flex flex-wrap justify-content-left">
          <div class="col-md-12 col-lg-5">
            <Map v-if="geoinfo && regions.length"
              :mapHeight="mapHeight"
              :zoom="calcZoom"
              :center="center"
              :locations="calcLocations"
              @hover-location="onHover"
              @click-location="onClick"
            />
            <div v-else>
              <CJumbotron>
                <h1 class="display-3">Add Regions</h1>
                <p class="lead">Wide range of administrative branches</p>
              </CJumbotron>
            </div>
          </div>
          <div class="col-md-12 col-lg-7">
            <CTableWrapper
              :items="regions"
              :fields="REGIONS_FIELDS"
              header-icon="cil-location-pin"
              add-btn
              caption="Region"
              hover
              fixed
              edit-btn
              :view-btn="false"
              :import-btn="false"
              :checkable="true"
              clickable-rows
              @add="onAddRegion"
              @edit="onEditRegion"
              @delete="onDeleteRegion"
              @row-click="onRegionClick"
              @delete-checked-items="onDeleteCheckedItems">
            </CTableWrapper>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import Map from '@/components/map/Map'
import CTableWrapper from '@/components/EdxTable'
// import GoogleMap from '@/components/GoogleMapLoader.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import RegionForm from '@/views/install/organizations/RegionForm.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SettingOrganization',

  components:{
    CTableWrapper,
    ConfirmModal,
    RegionForm,
    Map
  },
  data(){
    return {
      regions: [],
      region_selected: null,
      geoinfo: null,
      mapHeight: 450,
      center: [0,0],
      REGIONS_FIELDS : [
        {key: 'checkbox', label: '', _style:'width:50px;'},
        'name',
        {key: 'ops', label: 'Operations' , _style:"width:100px;"}
      ],
      form_title : "",
      view_sid: false
      // focus_position : {lat:37.566782, lng:126.978131}, // 좌측 구글맵에 포커싱할 위치
      // current_position : {lat:0, lng:0}, // 현재 위치 
      // selected_region : null,
    }
  },
  mounted(){
    // this.selected_region = null;
    this.getRegions();
  },
  computed : {
    ...mapState([
      'capability'
    ]),
    ...mapGetters([
      'myAuthorizationLevel',
      'myOrganization'
    ]),
    logo : function(){
      return this.myOrganization.logo || '/img/no-logo.jpg';
    },
    calcZoom() {
      // Location 분포에 따라 적당한 zoom level을 계산한다.
      if (!this.geoinfo) return;

      let large = Math.max(this.geoinfo.lat_len, this.geoinfo.lng_len);
      let meter = large * 87000;
      let meter_per_pixel = meter / this.mapHeight;
      if (meter_per_pixel > 4888*8) return 0.7;
      else if (meter_per_pixel > 4888*4) return 1.6;
      else if (meter_per_pixel > 4888*2) return 2.5;
      else if (meter_per_pixel > 4888) return 3.4;
      else if (meter_per_pixel > 2444) return 4.3;
      else if (meter_per_pixel > 1222) return 5.2;
      else if (meter_per_pixel > 610) return 6.1;
      else if (meter_per_pixel > 152) return 7;
      else if (meter_per_pixel > 76) return 8;
      else if (meter_per_pixel > 38) return 9;
      else if (meter_per_pixel > 19) return 10;
      else if (meter_per_pixel > 10) return 11;
      else if (meter_per_pixel > 5) return 12;
      else return 13;
    },
    calcLocations() {
      let locations = this.regions.map(el => {
        return {
          id: el.id,
          name: el.name,
          position: [el.lat, el.lng],
          is_selected: el.id === this.region_selected
        }
      });
      return locations;
    }
  },

  methods : {
    getRegions() {
      let query_string = '';
      query_string += "?organization="
      query_string += this.myOrganization.id;
      axios.get(`/api/regions/${query_string}`)
        .then(result => {
          this.regions = JSON.parse(JSON.stringify(result.data.list));
          this.geoinfo = JSON.parse(JSON.stringify(result.data.geoinfo));
          this.center = [this.geoinfo.lat_center, this.geoinfo.lng_center];
        });
    },
    onRegionClick(region){
      this.region_selected = region.id;
      this.center = [region.lat, region.lng];
    },
    logoPath(path){
      if(path===null)
        return this.logo_path+"default-logo.png";
      else
        return this.logo_path+path;
    },
    onEditRegion(region_id){
      this.form_title = "Edit Region";
      this.$refs.FormModal.open("edit", region_id);
    },
    onEditRegionPayload(payload){ // region edit
      payload['organization'] = this.myOrganization.id;
      axios.put(`/api/regions/${payload.id}/`, payload)
        .then(() => {
          this.getRegions();
          this.$notify.success({
            title: '완료',
            message: `항목이 변경되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onDeleteRegion : function(id){
      this.$refs.DeleteRegionConfirmModal.open(id);
    },
    onConfirmed : function(payload){
      // const del_name= this.searchRegionNameById(payload);
      axios.delete(`/api/regions/${payload}/`)
      .then(() => {
        this.getRegions();
        this.$notify.success({
          title: '완료',
          message: `항목이 삭제되었습니다`,
          offset: 30
        });
      })
      .catch(error => {
        console.error(error);
        this.$notify.error({
          title: '오류',
          message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
          offset: 30
        });
      });
    },
    onDeleteCheckedItems : function(delete_items){
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/regions/`, {data:fd})
        .then(() => {
          this.getRegions();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        });
    },
    onAddRegion(){
      if(this.regions.length >= 10){
        this.$notify.warning({
          title: '확인',
          message: `최대 10 지역을 생성할 수 있습니다`,
          offset: 30
        });
        return;
      }

      this.form_title = "New Region";
      let current_position = {};

      if (this.geoinfo && this.regions.length) {
        current_position["lat"] = this.geoinfo.lat_center;
        current_position["lng"] = this.geoinfo.lng_center;
        this.$refs.FormModal.open("add",current_position);
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            current_position["lat"] = position.coords.latitude;
            current_position["lng"] = position.coords.longitude;
            this.$refs.FormModal.open("add",current_position);
          },
          (error) => {
            console.log(error);
            current_position['lat'] = 37.566782; // default : 서울 시청
            current_position['lng'] = 126.978131;
            this.$refs.FormModal.open("add",current_position);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        console.log("Browser doesn't support Geolocation")
        current_position['lat'] = 37.566782; // default : 서울 시청
        current_position['lng'] = 126.978131;
        this.$refs.FormModal.open("add",current_position);
      }
    },
    onAddRegionPayload(payload) {
      payload['organization'] = this.myOrganization.id;
      axios.post(`/api/regions/`, payload)
        .then(()=>{
          this.getRegions();
          this.$notify.success({
            title: '완료',
            message: `항목이 추가되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    searchRegionNameById(region_id){
      let region_name="";
      for(let i=0; i<this.regions.length; i++){
        if(this.regions[i].id===region_id)
          return this.regions[i].name;
      }
      return region_name;
    },
    onHover(region_id) {
      let id = +region_id;
      this.region_selected = id;
    },
    onClick(region_id) {
      let id = +region_id;
      const clickRegion = this.regions.find(el => el.id === id);
      this.onRegionClick(clickRegion);
    },
    onClipboardCopy() {
      navigator.clipboard.writeText(this.myOrganization.sid);
      this.$message.success('copied!');
    }
    // selecteRegionByRegionId : function(region_id){
    //   let selected_region = [];
    //   for(let i=0; i<this.regions.length; i++){
    //     if(this.regions[i].id === region_id){
    //       selected_region = this.regions[i];
    //     }
    //   }
    //   return selected_region;
    // },

    // onEditRegion : function(region_id){
    //   let region = this.selecteRegionByRegionId(region_id);
    //   console.log(region)
    //   this.selected_region = region
    //   this.$refs.EditModal.open();
    // },

    // onEditRegionPayload : function(payload){ // region edit
    //   payload['organization'] = this.myOrganization.id;
    //   axios.put(`/api/regions/${payload.id}/`, payload)
    //     .then(result => {
    //       this.getRegions();
    //       this.selected_region = result.data;
    //       this.$notify.success({
    //         title: '완료',
    //         message: `항목이 변경되었습니다`,
    //         offset: 30
    //       });
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       this.$notify.error({
    //         title: '오류',
    //         message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
    //         offset: 30
    //       });
    //     })
    // },
    // onDeleteRegion : function(id){
    //   this.$refs.DeleteRegionConfirmModal.open(id);
    // },
  }
}
</script>

<style>
.info-icon {
  cursor: pointer;
  color: #888;
}
</style>
